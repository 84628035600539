import React from "react";

import ProductCard from "./ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

function HeadSection({ subdomain }) {
  return (
    <section className="container pt-5">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="m-0">Find Popular Places in</p>
          <h2 className="heading_dark py-2">{subdomain.title}</h2>
        </div>
        <div>
          <a className="see_more" href={subdomain.url}>
            See more
            <img src="/img/icons/right-arrow.svg" alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}
function TabButton({ title, id, active }) {
  return (
    <button
      className={`nav-link ${active ? "active" : ""}`}
      id={`nav-${id}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#nav-${id}`}
      type="button"
      role="tab"
      aria-controls={`nav-${id}`}
      aria-selected={active}
    >
      {title}
    </button>
  );
}
function TabPan({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade ${active ? "show active" : ""}`}
      id={`nav-${id}`}
      role="tabpanel"
      aria-labelledby={`nav-${id}-tab`}
    >
      {children}
    </div>
  );
}

function FilterTabSlider({ catData, subdomain }) {
  // sorting the product in alphabatical order

  const tabTitle = Object.keys(catData).sort();
  const sortedData = {};
  tabTitle.forEach((key) => {
    sortedData[key] = catData[key];
  });

  return (
    <>
      <HeadSection subdomain={subdomain} />
      {/* cards section start */}
      <section className="container">
        <div className="filter_search py-2">
          <div className="filters" id="nav-tab" role="tablist">
            {Object.entries(sortedData).map(([key, value], index) => {
              if (index === 0) {
                return (
                  <TabButton
                    title={value.title}
                    id={key + subdomain.slug}
                    active={true}
                  />
                );
              }
              return (
                <TabButton title={value.title} id={key + subdomain.slug} />
              );
            })}
          </div>
        </div>
        <div className="tab-content py-4" id="nav-tabContent">
          {Object.entries(sortedData).map(([key, value], index) => {
            if (index === 0) {
              return (
                <TabPan id={key + subdomain.slug} active={true}>
                  <Swiper
                    loop={true}
                    modules={[Pagination, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      499: {
                        slidesPerView: 1,
                        spaceBetweenSlides: 20,
                      },
                      991: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1399: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {value.products.map((product) => {
                      if (product.publishedAt) {
                        return (
                          <SwiperSlide>
                            <ProductCard
                              product={product}
                              subdomain={subdomain}
                            />
                          </SwiperSlide>
                        );
                      }
                      return null;
                    })}
                  </Swiper>
                </TabPan>
              );
            }
            return (
              <TabPan id={key + subdomain.slug}>
                <Swiper
                  loop={true}
                  modules={[Pagination, Autoplay]}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    499: {
                      slidesPerView: 1,
                      spaceBetweenSlides: 20,
                    },
                    991: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1399: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {value.products.map((product) => {
                    if (product.publishedAt) {
                      return (
                        <SwiperSlide>
                          <ProductCard
                            product={product}
                            subdomain={subdomain}
                          />
                        </SwiperSlide>
                      );
                    }
                    return null;
                  })}
                </Swiper>
              </TabPan>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default FilterTabSlider;
