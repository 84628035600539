import { useEffect, useState } from "react";
import { fetchData } from "../api";
import { categorizeData } from "../utils/utils";

function useLandingPageData() {
    const [landingPageData, setLandingPageData] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        async function fetchLandingPageData() {
            try {
                const response = await fetchData(
                    `/api/subdomains/?populate[products][populate][1]=product_type&populate[products][populate][2]=banner_image&populate[products][populate][3]=ticket_type&populate[products][populate][4]=transport_type&populate[products][populate][5]=time`
                );
                const productsData = response.data;

                if (productsData) {
                    // setLandingPageData();
                    const transformData = [];
                    productsData.forEach(element => {

                        transformData.push({
                            products: categorizeData(element),
                            subdomain: {
                                title: element.attributes.title,
                                slug: element.attributes.slug,
                                url: element.attributes.endpoint_url
                            }

                        })

                    });

                    setLandingPageData(transformData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error);
            }
        }

        fetchLandingPageData();
    }, []);

    return { landingPageData, error }; // Return the data and error
}

export default useLandingPageData;