export function categorizeData(data) {

    const categorizedData = {};
    const products = data.attributes.products.data || [];


    products.forEach(product => {
        const productType = product.attributes.product_type?.data?.attributes?.slug; // Access slug directly
        if (productType) {
            if (!categorizedData[productType]) {
                categorizedData[productType] = {
                    title: product.attributes.product_type.data.attributes.title,
                    products: []
                };
            }
            if (product.attributes.publishedAt) {
                categorizedData[productType].products.push(product.attributes);
            }

        }
    });

    return removeEmptyArray(categorizedData);

}

function removeEmptyArray(catData) {
    for (const key in catData) {
        if (catData[key].products.length < 1) {
            delete catData[key];
        }
    }
    return catData;
}