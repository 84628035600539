import React from "react";
import useSliderData from "../hooks/useSliderData";
import Loader from "./Loader";

export default function () {
  const { sliderData, error } = useSliderData();
  if (!sliderData) {
    return <Loader />;
  }

  return (
    <section className="container pt-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <h3 className="styled_text">Dream Vacation Destination</h3>

          <h2 className="heading_dark py-2">
            Plan the Trip of a Life time with Ease
          </h2>
        </div>
        <div className="destinations_contianer d-flex gap-4 flex-wrap col-12 col-md-6 justify-content-center justify-content-lg-end justify-content-md-start">
          {sliderData &&
            sliderData.map((dest) => {
              return (
                <a
                  href={dest.endpoint}
                  className="destination d-flex flex-column align-items-center text-decoration-none"
                >
                  <div
                    className="destination_image mb-2"
                    style={{ backgroundImage: `url(${dest.image})` }}
                  ></div>
                  <p className="destination_title">{dest.title}</p>
                </a>
              );
            })}
        </div>
      </div>
    </section>
  );
}
