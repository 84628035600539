import React from "react";
import { Helmet } from "react-helmet";
import HeaderSlider from "../components/HeaderSlider";
import FilterTabSlider from "../components/FilterTabSlider";
import Testimonial from "../components/Testimonial";
import Gradient from "../components/Gradient";
import Loader from "../components/Loader";
import useHomedata from "../hooks/useHomeData";
import useCategorizedData from "../hooks/useCatagoriesdData";
import Destinations from "../components/Destinations";
import useLandingPageData from "../hooks/uaseLandingPageData";

function Home() {
  const { homeData } = useHomedata();

  const { landingPageData } = useLandingPageData();

  if (!landingPageData || !homeData) {
    return <Loader />;
  }

  return (
    <>
      <HeaderSlider />
      <Destinations />
      {landingPageData &&
        landingPageData.map((data) => {
          return (
            <FilterTabSlider
              catData={data.products}
              subdomain={data.subdomain}
            />
          );
        })}

      <Testimonial />
      <Gradient />
    </>
  );
}

export default Home;
