import React, { useEffect, useState } from "react";
// import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { fetchData } from "../api";
import { Swiper, SwiperSlide } from "swiper/react";

function Slide({ data }) {
  return (
    <div className="swiper-slide">
      <div className="testimonial_card">
        <p className="testimonial_card__text">{data.attributes.description}</p>
        <div className="testimonial_card__user">
          <div className="testimonial_card__avatar">
            <img
              src={
                process.env.REACT_APP_API_URL +
                data.attributes.avatar.data.attributes.url
              }
              alt=""
            />
          </div>
          <div className="testimonial_card__user_detail">
            <p className="testimonial_card__user_detail_name">
              {data.attributes.author_name}
            </p>
            <p className="testimonial_card__user_detail_location">
              {data.attributes.location}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Testimonial() {
  const [testimonials, setTestimonials] = useState(null);
  function getData() {
    fetchData(`/api/testimonials?populate=*`)
      .then((res) => {
        const testimonial = res?.data;

        if (testimonial) {
          setTestimonials(testimonial);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <section
      className="testimonial"
      style={{ backgroundImage: 'url("/img/testimonial/bg.jpg")' }}
    >
      <div className="container position-relative mb-5">
        <h2 className="heading_light pt-5">Our customer reviews</h2>
        <p className="testimonial__para py-4">
          Our customer's feedback is essential in building a great reputation
          and maintaining excellent service. By listening to our customer's
          needs, we can improve our offerings and provide an exceptional travel
          experience.
        </p>
        <div className="slide_container position-relative">
          <div className="testimonial__navigation pt-4">
            <img
              src="/img/icons/arrow_left.svg"
              alt=""
              className="testimonial__button_prev"
            />
            <img
              src="/img/icons/arrow_right.svg"
              alt=""
              className="testimonial__button_next"
            />
          </div>
          <div className="swiper">
            <Swiper
              modules={[Navigation, Autoplay]}
              loop={true}
              navigation={{
                nextEl: ".testimonial__button_next",
                prevEl: ".testimonial__button_prev",
              }}
              breakpoints={{
                499: {
                  slidesPerView: 1,
                  spaceBetween: 300,
                },
                991: {
                  slidesPerView: 2,
                  spaceBetween: 300,
                },
              }}
            >
              {testimonials &&
                testimonials.map((testi) => {
                  return (
                    <SwiperSlide>
                      <Slide data={testi} key={testi.id} />;
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
