import Header from "./Header";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Thumbs } from "swiper/modules";
import useSliderData from "../hooks/useSliderData";
import Loader from "./Loader";

function HeaderSlider() {
  const { sliderData, error } = useSliderData();
  if (!sliderData) {
    return <Loader />;
  }

  return (
    <Swiper
      loop={true}
      slidesPerView={1}
      navigation={true}
      autoplay={true}
      modules={[Navigation, Autoplay]}
      className="header_slider"
    >
      {sliderData.map((slide) => {
        return (
          <SwiperSlide>
            <Header
              heading={slide.title}
              subheading={slide.subTitle}
              imgUrl={slide.image}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default HeaderSlider;
